
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import DeliveryChallans, {
  exportDeliveryChallanData,
  exportPDFDeliveryChallans,
} from "@/core/data/deliveryChallan";
import {
  DeliveryChallan,
  getDeliveryChallans,
  deleteDeliveryChallan,
} from "@/core/data/deliveryChallan";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";
import { PERMISSION_MODULE_NAME } from "@/core/data/permission";
import PartyMultiselect from "@/components/all-multiselect/party-multiselect.vue";

export default defineComponent({
  name: "DeliveryChallan-listing",
  components: {
    Datatable,
    PartyMultiselect,
  },
  setup() {
    const checkedDeliveryChallan = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    let selectedUser = ref();

    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllDeliveryChallan = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<DeliveryChallan>>(DeliveryChallans);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);
    const getListItems = function (pageNumber) {
      console.log("selectedUser.value", selectedUser.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }

      localStorage.setItem(
        "DeliveryChallanListAPiFilterData",
        JSON.stringify({
          currentPage: currentPage.value,
          searchData: searchData.value,
          selectedStatus: selectedStatus.value,
          selectedUser: selectedUser.value,
        })
      );
      return new Promise((res, rej) => {
        getDeliveryChallans(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedUser.value
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id] && id) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("DeliveryChallans Listing", [
        "Apps",
        "DeliveryChallans",
      ]);
      var deliveryChallanListAPiFilterData: any = localStorage.getItem(
        "DeliveryChallanListAPiFilterData"
      );
      if (deliveryChallanListAPiFilterData) {
        setTimeout(() => {
          deliveryChallanListAPiFilterData = JSON.parse(
            deliveryChallanListAPiFilterData
          );
          currentPage.value = deliveryChallanListAPiFilterData.currentPage;
          searchData.value = deliveryChallanListAPiFilterData.searchData;
          selectedStatus.value =
            deliveryChallanListAPiFilterData.selectedStatus;
          selectedUser.value = deliveryChallanListAPiFilterData.selectedUser;
          getListItems(currentPage.value);
        }, 500);
      } else {
        getListItems(1);
      }
    });

    const clearFilter = () => {
      currentPage.value = 1;
      searchData.value = {};
      selectedStatus.value = "";
      selectedUser.value = "";
      getListItems(1);
    };

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedDeliveryChallan.value.forEach((item) => {
        deleteDeliveryChallan(item);
      });
      checkedDeliveryChallan.value.length = 0;
    };
    const exportFewSites = () => {
      exportDeliveryChallanData(
        selectAllDeliveryChallan.value
          ? "all"
          : checkedDeliveryChallan.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your DeliveryChallan has been exported.",
          "success"
        );
      });
    };
    const exportPDFDeliveryChallansHanlder = () => {
      exportPDFDeliveryChallans(
        selectAllDeliveryChallan.value
          ? "all"
          : checkedDeliveryChallan.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your DeliveryChallan has been exported.",
          "success"
        );
      });
    };
    const deleteDeliveryChallanItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDeliveryChallan(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your DeliveryChallan has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addDeliveryChallan = (id) => {
      if (id) {
        router.push({ name: "apps-edit-delivery-challan", params: { id: id } });
      } else {
        router.push({ name: "apps-add-delivery-challan" });
      }
    };
    const stockDeliveryChallanItem = (id) => {
      router.push({ name: "apps-stock-delivery-challan", params: { id: id } });
    };
    const designMaterialDeliveryChallanLanding = (id, designId) => {
      router.push({
        name: "apps-design-delivery-challan-landing",
        params: { id: id, designId: designId },
      });
    };
    const fusingDeliveryChallanLanding = (id, designId) => {
      router.push({
        name: "apps-fusing-deliveryChallan",
        params: { id: id, designId: designId },
      });
    };
    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasCreate = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DELIVERY_CHALLAN].create
      );
    });
    const hasEdit = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DELIVERY_CHALLAN].update
      );
    });
    const hasDelete = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DELIVERY_CHALLAN].delete
      );
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Challan Number",
          key: "challan_number",
          sortable: true,
        },
        {
          name: "Date",
          key: "date",
          sortable: false,
        },
        {
          name: "Party",
          key: "party",
          sortable: false,
        },
        {
          name: "Grand Total",
          key: "grand_total",
          sortable: false,
        },
      ];
      if (hasEdit.value || hasDelete.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });
    return {
      addDeliveryChallan,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteDeliveryChallan,
      getDeliveryChallans,
      deleteDeliveryChallanItem,
      search,
      searchItems,
      checkedDeliveryChallan,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedUser,
      asyncUser,
      exportFewSites,
      exportPDFDeliveryChallansHanlder,
      selectAllDeliveryChallan,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
      stockDeliveryChallanItem,
      designMaterialDeliveryChallanLanding,
      fusingDeliveryChallanLanding,
      clearFilter,
    };
  },
});
